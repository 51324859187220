import * as courseSearchFacets from '../constants/facetKeys'
import DateUtils from './DateUtils'

export default class FacetUtils {

    constructor() {
        throw new Error('This is a static class')
    }

    /**
     * add a count property to each facet
     * 
     * @param {number} count max number of facets to display
     * @param  {...string} facets to display
    */
    static setCount(count, ...facets) {
        return facets.map(f => f ? `${f},count:${count}` : f)
    }

    /**
     * get the facet with only existing values, for example get the start moments facet with only 
     * start dates for the selected locations
     * 
     * @param {Array} selectedFilters an array of selected items
     * @param  {Array} facets an array of facets
     * @param  {string} facetType the facet type, supported values: LOCATIONS | STARTMOMENTS
     * @return {Array}
    */
    static getExistingLocationsOrStartMoments(selectedFilters, facets, facetType) {
        const startMomentYearAndMonthAndCity = this.getStartMomentYearAndMonthAndCity(facets)
        if (!startMomentYearAndMonthAndCity) return []

        const existingEvents = startMomentYearAndMonthAndCity.reduce((acc, f) => {
            const startMoment = f.key.slice(0, f.key.indexOf('-'))
            const location = f.key.slice(f.key.indexOf("-") + 1)
            
       
            const hasLocation = selectedFilters.some(x => x.toLowerCase() === location.toLowerCase())
            if(facetType === courseSearchFacets.STARTMOMENTS && startMoment && hasLocation) {
                acc.push(startMoment)
            }

            const hasStartMoment = selectedFilters.includes(startMoment)
            if(facetType === courseSearchFacets.LOCATIONS && location && hasStartMoment) {
                acc.push(location)
            }

            return acc
        }, [])

        return existingEvents        
    }

    /**
     * get the not existing facet values
     * 
     * @param  {Array} facetWithExistingValue an array of existing facet values  
     * @param {Array} selectedValues an array of selected items
     * @return {Array}
    */
    static getNotExistingValues(facetWithExistingValue, selectedValues) {
        return selectedValues.reduce((acc, sv) => {
            if(!facetWithExistingValue.some(f => sv.toLowerCase() === f.key.toLowerCase())) acc.push(sv)

            return acc
        }, [])
       
    }
    
    /**
     * get existing values
     * 
     * @param {Array} allValues an array of all the facet values
     * @param  {Array} existingValues an array of existing events (locations or startmoments)
     * @return {Array}
    */
    static getExistingValues(allValues, existingValues) {
        return allValues.filter(fv => {
            const hasValue = existingValues.some(v => v.toLowerCase() === fv.key.toLowerCase())
            
            return hasValue
        })
    }

    /**
     * get startMomentsArray/yearAndMonthAndCity facet
     * 
     * @param {Array} facets the facets
     * @return {Object}
    */
    static getStartMomentYearAndMonthAndCity(facets) {
        const facetValues = facets?.reduce((acc, f) =>  {
            if (f.key === courseSearchFacets.STARTMOMENTYEARANDMONTHANDCITY) {
                return [
                    ...acc,
                    ...f.values
                ]
            }

            return acc
        }, [])

        return facetValues
    }

    /**
     * get an array of unique selected facet values 
     * 
     * @param {Array} selectedFilters the selected facet filters
     * @param  {string} facetType the facet type
     * @return {Array}
    */
    static getFacetSelectedValues(selectedFilters, facetType) {
        const selectedFacetValue = selectedFilters.reduce((acc, f) =>  {
            if (f.name === facetType) {
                return [
                    ...acc,
                    ...f.values
                ]
            }

            return acc
        }, [])

        return [...new Set([...selectedFacetValue])]
    } 

    /**
     * check if the selected facet is a composite filter
     * 
     * @param {Array} selectedFilters the selected facet filters
     * @return {boolean}
    */
    static isCompositeFacetSelected(selectedFilters) {
        const compositeFacets = [courseSearchFacets.LOCATIONS, courseSearchFacets.STARTMOMENTS]
        return selectedFilters?.some(f =>  compositeFacets.includes(f.name))
    }
    
    /**
     * add startMomentsArray/yearAndMonthAndCity
     * and remove filters by locations and yearAndMonth since they are redundant filter by yearAndMonthAndCity
     * 
     * @param {Array} startMomentsFilter the selected startMoments
     * @param {Array} locationFilters the selected locations
     * @return {Array}
    */
    static addYearAndMonthAndCityFilter(filters, startMomentsFilter, locationFilters) {
        const updatedFilters = [...filters]
        const values = startMomentsFilter.reduce((acc, sf) => {
            locationFilters.forEach(lf => {
                acc.push(`${sf}-${lf}`)
            })  
            return acc
        }, [])

        updatedFilters.push({
            name: courseSearchFacets.STARTMOMENTYEARANDMONTHANDCITY,
            values
        })

        return updatedFilters?.filter(x => x.name !== courseSearchFacets.LOCATIONS && x.name !== courseSearchFacets.STARTMOMENTS)
    }

    /**
     * get existing startMomentsArray/yearAndMonth or startMomentsArray/Locations facet values
     * 
     * @param {Array} facets the facets
     * @return {Object}
    */
    static getExistingFacetValuesByFacetType(facets, facetType) {
        const facetValues = facets?.reduce((acc, f) =>  {
            if (f.key === facetType) {
                return [
                    ...acc,
                    ...f.values
                ]
            }

            return acc
        }, [])

        return facetValues
    }

    /**
     * get startMomentsArray/yearAndMonth facet values within the final startmoment date
     * 
     * @param {Array} facets the facets
     * @param {dateTime} finalStartMomentDate the final startmoment date 
     * @return {Object}
    */
    static getFilteredYearAndMonthFacetValues(existingFacetValues, finalStartMomentDate) {
        return this.getFilteredStartMomentsOrLocations(existingFacetValues, finalStartMomentDate, courseSearchFacets.STARTMOMENTS)
    }

    /**
     * get filtered Location facet values that are within the final startmoment date
     * 
     * @param {Array} existingFacetValues the existing facets 
     * @param {dateTime} finalStartMomentDate the final startmoment date 
     * @return {Object}
    */
    static getFilteredLocationsFacetValues(existingFacetValues, finalStartMomentDate) {
        const existingStartMomentsYearAndMonthAndCityValues = this.getStartMomentYearAndMonthAndCity(existingFacetValues)
        const filteredLocationValues = this.getFilteredStartMomentsOrLocations(existingStartMomentsYearAndMonthAndCityValues, finalStartMomentDate, courseSearchFacets.LOCATIONS)
        const existingLocationFacetValues = this.getExistingFacetValuesByFacetType(existingFacetValues, courseSearchFacets.LOCATIONS) 
        return existingLocationFacetValues.filter(f => filteredLocationValues.some(fl => fl === f.key))
    }

    /**
     * get filtered StartMoments or Location facet values that are within the final startmoment date
     * 
     * @param {Array} existingFacetValues the existing facets
     * @param {dateTime} finalStartMomentDate the final startmoment date 
     * @param  {string} facetType the facet type
     * @return {Object}
    */
    static getFilteredStartMomentsOrLocations(existingFacetValues, finalStartMomentDate, facetType) {
        const finalStartMomentMonth = DateUtils.getMonth(finalStartMomentDate) + 1
        const finalStartMomentYear = DateUtils.getYear(finalStartMomentDate)
        const filteredFacetValues = existingFacetValues.reduce((acc, f) =>  {
            const startMomentYear = f.key.slice(0, 4)
            const startMomentMonth = f.key.slice(4,6)
            const startMomentLocation = f.key.slice(7)
            if (Number(startMomentMonth) <= finalStartMomentMonth && Number(startMomentYear) <= finalStartMomentYear) {
                if (facetType === courseSearchFacets.STARTMOMENTS) acc.push(f)
                else if (facetType === courseSearchFacets.LOCATIONS) acc.push(startMomentLocation)
            }
            return acc
        }, [])

        return [...new Set([...filteredFacetValues])]
    }
}
