import { getAiClient } from './apiClient'

/** @typedef {import('../models').CourseRecommendationRequest} CourseRecommendationRequest */

const baseUrl = '/course-recommendation'

/**
 * Retrieve ai course recommendation topics
 * 
 * @param {CourseRecommendationRequest} courseRecommendationRequest 
 * @returns {Promise<string>}} 
 */
const getRecommendationTopics = async courseRecommendationRequest => {
    return (await getAiClient())
        .post(`${baseUrl}/topics`, courseRecommendationRequest)
        .then(res => res.data)
}

export default {
    getRecommendationTopics
}