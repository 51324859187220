<template>
  <div class="c-enrollment-step c-enrollment-confirmation">
    <h4 class="u-textcolor-primary">Controle gegevens</h4>
    <p>{{ title }}</p>

    <!-- Step 0: Price -->
    <div
      v-if="$isAllowed('displayPricingInformation')"
      class="row"
    >
      <h4 class="col-12 u-textcolor-primary">Prijs Samenvatting</h4>
      <div
        v-if="prices"
        class="mb-5 col-12 col-md-10"
      >
        <div
          v-if="prices.listPrice && (prices.discount || prices.vat)"
          class="mb-2 d-flex justify-content-between"
        >
          <span class="font-weight-bold">Prijs:</span>
          <span data-test="list-price">{{ formatPriceWithZeros(prices.listPrice) }}</span>
        </div>
        <div
          v-if="prices.discount"
          class="mb-2 d-flex justify-content-between"
        >
          <span class="font-weight-bold">Korting:</span>
          <span data-test="price-discount">- {{ formatPriceWithZeros(prices.discount) }}</span>
        </div>
        <div
          v-if="prices.vat"
          class="mb-2 d-flex justify-content-between"
        >
          <span class="font-weight-bold">BTW:</span>
          <span data-test="price-vat">{{ formatPriceWithZeros(prices.vat) }}</span>
        </div>
        <recess-divider
          v-if="prices.vat || prices.discount || (prices.listPrice && (prices.discount || prices.vat))"
          variant="xsmall"
          show-line
          class="col-4 ml-auto"
        />
        <div class="mb-2 d-flex justify-content-between">
          <span class="font-weight-bold">{{ enrollmentType === enrollmentTypes.TEAMLEADER || enrollmentType === enrollmentTypes.COORDINATOR ? 'Per deelnemer:' : 'Totaal:' }}</span>
          <div v-if="prices.totalPrice || prices.totalPrice === 0">
            <span
              data-test="total-price-text"
              class="font-italic u-text-small pr-1"
            >({{ vatText }})</span>
            <span data-test="total-price">{{ formatPriceWithZeros(prices.totalPrice) }}</span>
          </div>
        </div>

        <recess-alert
          v-if="showHighestStartmomentPriceWarning"
          class="my-3"
          type="info"
          data-test="highest-start-moment-price-alert"
          :text="highestStartmomentPriceWarningMessage"
        />
        <!-- Budget list -->
        <div
          v-if="usedBudgetsAmount"
          class="mt-4"
        >
          <span class="font-weight-bold">Aftrek door gebruik van budget(ten):</span>
          <template
            v-for="(budget, index) in formData.budgets"  
          >
            <div
              v-if="budget.selectedAmount"
              :key="index"
              class="row d-flex align-items-center"
            >
              <span class="col-6 col-md-4">
                {{ `${budget.name}${budget.approvalRequired ? '*' : ''}` }}</span>

              <span class="col-4 d-none d-md-flex">Resterend: {{ formatPriceWithZeros(budget.currentAmount - (+budget.selectedAmount || 0)) }} </span>

              <div class="col">
                <div class="row">
                  <span class="col d-none d-sm-flex justify-content-end">Gebruikt:</span>
                  <span class="col-sm-5 col-md d-flex justify-content-end">- {{ formatPriceWithZeros(budget.selectedAmount) }}</span>
                </div>
              </div>
            </div>
          </template>

          <recess-divider
            v-if="formData.budgets && formData.budgets.length"
            variant="xsmall"
            show-line
            class="col-4 ml-auto"
          />
    
          <!-- Total used budget and remaining amount -->
          <div class="row mt-4">
            <span class="col-6 col-md d-flex justify-content-md-end">Totaal aangewend budget:</span>
            <span class="col-6 col-md-2 d-flex justify-content-end">- {{ formatPriceWithZeros(usedBudgetsAmount) }}</span>
          </div>
          <recess-divider
            variant="xsmall"
            show-line
            class="col-5 ml-auto"
          />
          <div class="row font-weight-bold">
            <span class="col-6 col-md d-flex justify-content-md-end">Te betalen door werkgever **</span>
            <div class="col-6 col-md-2 pl-0 d-flex justify-content-end">
              <span
                v-if="prices.totalPrice"
                class="font-italic mr-2 u-text-small"
                data-test="total-price-text"
              >({{ vatText }})</span>
              <span>{{ formatPriceWithZeros(remainingTotalAmount) }}</span>
            </div>
          </div>
        </div>
      </div>        
    </div>

    <!-- Extra informatie - team leader only -->
    <template v-if="enrollmentType === enrollmentTypes.TEAMLEADER">
      <h4 class="u-textcolor-primary">Extra informatie</h4>
      <div
        v-if="costCenter && $isAllowed('displayCostCenter')"
        class="mb-2 d-flex"
      >
        <div class="w-50">
          <span class="c-enrollment-confirmation__column-label">Kostenplaats:</span>
        </div>
        <div class="w-50">
          <span>{{ costCenter }}</span>
        </div>
      </div>
      <template v-if="purchaseOrderNumber && $isAllowed('displayPONumber')">
        <div class="mb-2 d-flex">
          <div class="w-50">
            <span class="c-enrollment-confirmation__column-label">PO nummer:</span>
          </div>
          <div class="w-50">
            <span>{{ purchaseOrderNumber }}</span>
          </div>
        </div>
      </template>
      <template v-if="selectedStudyContract">
        <div class="mb-2 d-flex">
          <div class="w-50">
            <span class="c-enrollment-confirmation__column-label">Studieovereenkomst:</span>
          </div>
          <div class="w-50">
            <span>{{ selectedStudyContract }}</span>
          </div>
        </div>
      </template>
      <template v-if="selectedSecondApprover">
        <div class="mb-2 d-flex">
          <div class="w-50">
            <span class="c-enrollment-confirmation__column-label">Toegewezen tweede beoordelaar:</span>
          </div>
          <div class="w-50">
            <span>{{ selectedSecondApprover }}</span>
          </div>
        </div>
      </template>
      <template v-if="selectedKeyAccount && $isAllowed('displayKeyAccounts')">
        <div class="mb-2 d-flex">
          <div class="w-50">
            <span class="c-enrollment-confirmation__column-label">Key-Account:</span>
          </div>
          <div class="w-50">
            <span>{{ selectedKeyAccount }}</span>
          </div>
        </div>
      </template>
      <template v-if="formData.isSubsidized && isSubsidyEnabled">
        <div class="mb-2 d-flex">
          <div class="w-50">
            <span class="c-enrollment-confirmation__column-label">Subsidie:</span>
          </div>
          <div class="w-50">
            <span>Ja</span>
          </div>
        </div>
      </template>
      <recess-divider v-if="startMoments && startMoments.length" />
    </template>

    <!-- Step 1: Startmoments and locations -->
    <template v-if="startMoments && startMoments.length">
      <h4 class="u-textcolor-primary">Datum en locatie</h4>
      <start-moment-selection
        :course="course"
        :selected-start-moments="selectedStartMoments"
        startmoment-updates-enabled
        :show-capacity="showCapacity"
        :product-type="courseProductType"
        @clear-start-moment="removeStartMomentAndGoFirstStep"
        @clear-start-moments="removeStartMomentAndGoFirstStep"
      />
    </template>

    <recess-divider />

    <!-- Step 2-->
    <template v-if="enrollmentType !== enrollmentTypes.STUDENT">
      <h4 class="u-textcolor-primary">Geselecteerde studenten {{ coordinatorStudentSubtitle }}</h4>
      <recess-table>
        <recess-table-head>
          <recess-table-row>
            <recess-table-head-item
              v-for="headerItem in tableHeader"
              :key="headerItem.key"
            >
              {{ headerItem.displayTitle }}
            </recess-table-head-item>
          </recess-table-row>
        </recess-table-head>
        <recess-table-body>
          <recess-table-row
            v-for="student in selectedStudents"
            :key="student.userId"
          >
            <recess-table-cell :data-label="tableHeader[0].displayTitle">{{ student.fullName }}</recess-table-cell>
            <recess-table-cell
              :data-label="tableHeader[1].displayTitle"
            >
              {{ student.company && student.company.name }}
            </recess-table-cell>
            <recess-table-cell
              v-if="enrollmentType === enrollmentTypes.COORDINATOR"
              :data-label="tableHeader[2].displayTitle"
            >
              {{ getApprover(student) }}
            </recess-table-cell>
            <recess-table-cell
              v-if="enrollmentType === enrollmentTypes.TEAMLEADER"
              :data-label="tableHeader[2].displayTitle"
            >
              <RecessIcon
                :icon="isInStudentAssortment(student) ? 'check' : 'cross'"
                color="black"
              />
            </recess-table-cell>
          </recess-table-row>
        </recess-table-body>
      </recess-table>
      <recess-divider />
    </template>

    <template v-if="enrollmentType === enrollmentTypes.STUDENT">
      <h4 class="u-textcolor-primary">Persoonlijke gegevens</h4>
      <!-- Persoonsgegevens -->
      <table
        aria-label="Student detail"
        class="c-enrollment-confirmation__table"
      >
        <!-- FirstName text -->
        <tbody>
          <tr>
            <th
              scope="row"
              class="c-enrollment-confirmation__column-label"
            >
              Voornaam
            </th>
            <td class="c-enrollment-confirmation__column-value">{{ formData.firstName }}</td>
          </tr>
          <!-- MiddleName text -->
          <tr>
            <th
              scope="row"
              class="c-enrollment-confirmation__column-label"
            >
              Tussenvoegsel(s)
            </th>
            <td class="c-enrollment-confirmation__column-value">{{ formData.middleName }}</td>
          </tr>
          <!-- LastName text -->
          <tr>
            <th
              scope="row"
              class="c-enrollment-confirmation__column-label"
            >
              Achternaam
            </th>
            <td class="c-enrollment-confirmation__column-value">{{ formData.lastName }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Geboortegegevens -->
      <table
        v-if="$isAllowed('displayPersonalInformation') && ($isAllowed('displayBirthDateField') || $isAllowed('displayBirthPlaceField'))"
        aria-label="Student birthday detail"
        class="c-enrollment-confirmation__table"
      >
        <tbody>
          <!-- Birthday text -->
          <tr v-if="$isAllowed('displayBirthDateField')">
            <th
              scope="row"
              class="c-enrollment-confirmation__column-label"
            >
              Geboortedatum
            </th>
            <td class="c-enrollment-confirmation__column-value">{{ formData.birthday }}</td>
          </tr>
          <!-- Birthplace text -->
          <tr v-if="$isAllowed('displayBirthPlaceField')">
            <th
              scope="row"
              class="c-enrollment-confirmation__column-label"
            >
              Geboorteplaats
            </th>
            <td class="c-enrollment-confirmation__column-value">{{ formData.birthplace }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Contactgegevens -->
      <table
        aria-label="Student contact detail"
        class="c-enrollment-confirmation__table"
      >
        <tbody>
          <!-- Email text -->
          <tr>
            <th
              scope="row"
              class="c-enrollment-confirmation__column-label"
            >
              E-mailadres
            </th>
            <td class="c-enrollment-confirmation__column-value">{{ formData.emailAddress }}</td>
          </tr>
          <!-- PhoneNumber text -->
          <tr v-if="$isAllowed('displayPersonalInformation')">
            <th
              scope="row"
              class="c-enrollment-confirmation__column-label"
            >
              Telefoonnummer
            </th>
            <td class="c-enrollment-confirmation__column-value">{{ formData.telephone }}</td>
          </tr>
        </tbody>
      </table>

      <template v-if="hasAlternativeBillingAddress && useAlternativeBilling">
        <h4 class="u-textcolor-primary">Factuuradres</h4>

        <alternative-billing-summary :form-data="formData.billingAddress" />
      </template>

      <!-- Adresgegevens -->
      <template v-if="$isAllowed('displayPersonalInformation')">
        <h4 class="u-textcolor-primary">Adresgegevens</h4>

        <table
          aria-label="Student address detail"
          class="c-enrollment-confirmation__table"
        >
          <!-- Address PostalCode text -->
          <tbody>
            <tr>
              <th
                scope="row"
                class="c-enrollment-confirmation__column-label"
              >
                Postcode
              </th>
              <td class="c-enrollment-confirmation__column-value">{{ formData.postalCode }}</td>
            </tr>
            <!-- Address HouseNumber/HouseNumberAddition text -->
            <tr>
              <th
                scope="row"
                class="c-enrollment-confirmation__column-label"
              >
                Huisnummer
              </th>
              <td
                class="c-enrollment-confirmation__column-value"
              >
                {{ formData.number }} {{ formData.numberAdditive }}
              </td>
            </tr>
            <!-- Address Street text -->
            <tr>
              <th
                scope="row"
                class="c-enrollment-confirmation__column-label"
              >
                Straat
              </th>
              <td class="c-enrollment-confirmation__column-value">{{ formData.street }}</td>
            </tr>
            <!-- Address City	text -->
            <tr>
              <th
                scope="row"
                class="c-enrollment-confirmation__column-label"
              >
                Plaats
              </th>
              <td class="c-enrollment-confirmation__column-value">{{ formData.city }}</td>
            </tr>
            <!-- Address CountryCode	text -->
            <tr>
              <th
                scope="row"
                class="c-enrollment-confirmation__column-label"
              >
                Land
              </th>
              <td class="c-enrollment-confirmation__column-value">{{ formDataCountryDisplayValue }}</td>
            </tr>
          </tbody>
        </table>
      </template>
      
      <template v-if="$isAllowed('displayEnrollReason') && enrollReasonDisplayValue">
        <enrollment-reason-summary 
          :enroll-reason-display-value="enrollReasonDisplayValue"
          :enroll-reason-message="formData.enrollReasonMessage"/>
        <recess-divider />
      </template>
            
      <div v-if="approvalRequired">
        <!-- Team leader -->
        <h4 class="u-textcolor-primary">Leidinggevende</h4>

        <table
          aria-label="Teamleader email"
          class="c-enrollment-confirmation__table"
        >
          <tbody>
            <tr>
              <th
                scope="row"
                class="c-enrollment-confirmation__column-label"
              >
                E-mailadres
              </th>
              <td class="c-enrollment-confirmation__column-value">{{ teamLeaderEmailDisplayValue }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p
        v-if="formData.willUpdateUserData"
      >
        Uw profiel zal bijgewerkt worden met de bovenstaande gegevens.
      </p>
    </template>

    <!-- Learning result -->
    <template v-if="showSelectedLearningResults" >
      <learning-result-sharing-summary />

      <recess-divider />
    </template>

    <p class="mb-0">
      Op deze inschrijving zijn de eerder door u geaccepteerde
      <a
        :href="legalAgreementUrl"
        target="blank"
      >algemene voorwaarden</a>
      van toepassing.
    </p>

    <enrollment-step-buttons 
      next-button-text="Inschrijving afronden"
      v-on="{ ...$listeners, 'next-step': () => $emit('submit') }" 
    />
    
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import * as enrollmentSteps from '../../../../constants/enrollmentSteps'
import LegalAgreementsClient from '../../../../api/legalAgreementsClient'
import { getAssortedProducts } from '../../../../api/assortedProductClient'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'
import * as enrollmentTypes from '../../../../constants/enrollmentTypes'
import priceFormatter from '../../../../modules/priceFormatter'
import numberFormatter from '../../../../modules/numberFormatter'
import ODataFilterOperations from '../../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../../api/OData/odataQueryFilter'
import AlternativeBillingSummary from './Summary/AlternativeBillingSummary.vue'
import StartMomentSelection from '../../StartMomentSelection/StartMomentSelection.vue'
import coursePriceHelper from '../../../../modules/coursePriceHelper'
import EnrollmentStepButtons from './EnrollmentStepButtons.vue'
import { highestStartmomentPriceWarningMessage } from '../../../../constants/highestStartmomentPriceWarningMessage'
import LearningResultSharingSummary from './Summary/LearningResultSharingSummary.vue'
import EnrollmentReasonSummary from '../../../atoms/EnrollmentReasonSummaryComponent/EnrollmentReasonSummary.vue'

export default {
    name: "EnrollmentStepSummary",
    components: {
        AlternativeBillingSummary,
        StartMomentSelection,
        EnrollmentStepButtons,
        LearningResultSharingSummary,
        EnrollmentReasonSummary
    },
    perimeters: [featuresPerimeter],
    data() {
        return {
            learningResultTypeToShow: null,
            legalAgreementUrl: null,
            assortedProducts: [],
            enrollmentTypes,
            highestStartmomentPriceWarningMessage
        }
    },
    computed: {
        ...mapGetters('startMomentsModule', ['startMoments']),
        ...mapState('enrollmentModule', [
            'enrollmentType',
            'formData',
            'countries',
            'teamLeaders',
            'approvalRequired',
            'prices',
            'teamleadersPerCompany',
            'course',
            'enrollReasons',
            'selectedLearningResultOptions'
        ]),
        ...mapGetters('enrollmentModule', [
            'selectedStudents',
            'selectedCompanyTeamleaders',
            'costCenter',
            'purchaseOrderNumber',
            'selectedSecondApprover',
            'selectedStudyContract',
            'selectedTeamLeaderEmail',
            'useAlternativeBilling',
            'keyAccounts',
            'selectedStartMoments',
            'showCapacity',
            'courseProductType',
            'coursePrice',
            'showHighestStartmomentPriceWarning',
            'course'
        ]),
        ...mapGetters('accountModule', [
            'displayVatInPrices',
            'selectFirstApproverWithSearchInput',
            'isSubsidyEnabled',
            'hasAlternativeBillingAddress',
            'isLearningResultEnabled'
        ]),
        showSelectedLearningResults() {
            return this.selectedLearningResultOptions && Object.keys(this.selectedLearningResultOptions)?.length && this.isLearningResultEnabled
        },  
        enrollReasonDisplayValue() {
            return this.enrollReasons?.find(x => x.value === this.formData.enrollReasonCode)?.displayText
        },
        selectedKeyAccount() {
            if(this.$isAllowed('displayKeyAccounts')) {
                return this.keyAccounts?.find(k => k.id === this.formData?.keyAccountId)?.name || "Geen key-account"
            }
            return null
        },
        tableHeader() {
            const tableHeaderArr = [
                {
                    key: 'fullName',
                    displayTitle: 'Naam'
                },
                {
                    key: 'companyName',
                    displayTitle: 'Afdeling'
                }
            ]

            if (this.enrollmentType === enrollmentTypes.TEAMLEADER)
                tableHeaderArr.push({
                    key: 'isInAssortment',
                    displayTitle: 'In assortiment'
                })

            if (this.enrollmentType === enrollmentTypes.COORDINATOR)
                tableHeaderArr.push({
                    key: 'reviewer',
                    displayTitle: 'Beoordelaar'
                })
            return tableHeaderArr
        },
        title() {
            if (this.enrollmentType === enrollmentTypes.STUDENT) {
                return 'Controleer of de door jou ingevulde gegevens juist zijn overgenomen. Wanneer alle gegevens juist zijn klik je onderaan de pagina op Verzenden om jouw inschrijving te bevestigen.'
            }
            return 'Controleer of de door jou ingevulde gegevens juist zijn. Wanneer alle gegevens juist zijn klik je onderaan de pagina op Inschrijving afronden om alle inschrijvingen te bevestigen.'
        },
        coordinatorStudentSubtitle() {
            return this.enrollmentType === enrollmentTypes.COORDINATOR ? 'en beoordelaars' : ''
        },
        vatText() {
            return coursePriceHelper.getVatText(this.displayVatInPrices)
        },
        usedBudgetsAmount() {
            return this.roundToTwo(this.formData?.budgets?.reduce((acc, budget) => acc + (+budget.selectedAmount || 0), 0))
        },
        remainingTotalAmount() {
            return this.roundToTwo(this.coursePrice - this.usedBudgetsAmount)
        },
        formDataCountryDisplayValue() {
            const country = this.countries.find(m => m.value === this.formData.country)
            return country ? country.displayText : ''
        },
        teamLeaderEmailDisplayValue() {
            let teamleader 
            if(!this.selectFirstApproverWithSearchInput) {
                teamleader = this.teamLeaders?.find(
                    item => item.userId === this.formData.assignedApproverId
                )?.emailAddress

            } else {
                teamleader = this.selectedTeamLeaderEmail
            }
           
            return teamleader ?? 'De leidinggevende kon niet worden opgehaald.'
        } 

    },
    mounted() {
        if (this.enrollmentType !== enrollmentTypes.STUDENT || this.selectedStartMoments?.length) {
            this.getCoursePrice()
        }
    
        if (this.enrollmentType === enrollmentTypes.STUDENT)
            this.$gtag.pageview({ page_path: '/inschrijven-student-controleer' })

        if (this.enrollmentType === enrollmentTypes.TEAMLEADER) {
            this.fetchAssortedProducts()
            this.$gtag.pageview({ page_path: '/inschrijven-teamlead-controleer' })
        }

        this.fetchLegalAgreement()
    },
    methods: {
        ...mapMutations('enrollmentModule', ['setCurrentStepCode', 'clearSelectedStartMoment', 'clearStartMoments']),
        ...mapActions('enrollmentModule', ['getCoursePrice']),
        formatPrice: priceFormatter.formatPrice,
        formatPriceWithZeros: priceFormatter.formatPriceWithZeros,
        roundToTwo: numberFormatter.roundToTwo,  
        fetchLegalAgreement() {
            LegalAgreementsClient.getLegalAgreements(false).then(response => {
                if (response.length) this.legalAgreementUrl = response.find(x => x.isEnabled)?.url
            })
        },
        async fetchAssortedProducts() {
            const oDataQuery = new ODataQueryBuilder()
                .setPagination(0, 9999)
                .setSort('productReference', 'asc')
                .addFilter(
                    new ODataQueryFilter(
                        'productReference',
                        ODataFilterOperations.EQUALS,
                        `course:${this.course?.id}`
                    )
                )
                .build()

            await getAssortedProducts(oDataQuery).then(response => {
                if (response.length) this.assortedProducts = response
            })
        },
        getApprover(student) {
            return this.selectedCompanyTeamleaders[student?.companyId]?.fullName || '-'
        },
        isInStudentAssortment(user) {
            return !user?.assortments?.length || this.assortedProducts.some(ap => user?.assortments?.some(a => a.assortmentId === ap.assortmentId))
        },
        removeStartMomentAndGoFirstStep(startMomentIndex) {
            if(startMomentIndex) this.clearSelectedStartMoment(startMomentIndex)
            else this.clearStartMoments()

            this.setCurrentStepCode(enrollmentSteps.STARTMOMENTS.code)
        }
    }
}
</script>