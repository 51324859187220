import { ref, computed } from 'vue'

export default function useTableData({ defaultPageSize, defaultSort }) {
    const pageSize = ref(defaultPageSize)
    const setPageSize = newValue => { pageSize.value = newValue }

    const pageIndex = ref(1)
    const setPageIndex = newValue => { pageIndex.value = newValue }

    const skip = ref(0)
    const setSkip = newValue => { skip.value = newValue }

    const take = ref(defaultPageSize)
    const setTake = newValue => { take.value = newValue }

    const totalCount = ref(0)
    const setTotalCount = newValue => { totalCount.value = newValue }

    const sort = ref(defaultSort ?? { key: null, direction: null })
    const setSort = newValue => { sort.value = newValue }

    const data = ref([])
    const setData = newValue => { data.value = newValue }

    const totalPages = computed(() => {
        if (data.value?.length) {
            return Math.ceil(totalCount.value / pageSize.value)
        }
        return 1
    })

    const resetPagination = async defaultTake => {
        setSkip(0)
        setPageIndex(1)
        setTake(defaultTake)
    }

    return {
        pageSize, setPageSize,
        pageIndex, setPageIndex,
        skip, setSkip,
        take, setTake,
        totalCount, setTotalCount,
        sort, setSort,
        data, setData,
        totalPages,
        resetPagination
    }
}