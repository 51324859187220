<template>
    <recess-layout-static v-if="$isAllowed('routeMyTeamPage')" :size="1700">
        <recess-divider />
        <h1>Mijn team</h1>
        <recess-divider />

        <template v-if="isMobile">
            <template v-for="(tab, index) in Object.keys(myTeamTabs)">
                <recess-accordion
                    v-if="isTabVisible(myTeamTabs[tab].key)"
                    :key="index"
                    :active="currentComponent && currentComponent.key === myTeamTabs[tab].key"
                    variant="variant-3"
                    @toggled="(isActive) => toggledTab(isActive, myTeamTabs[tab].key)"
                >
                    <template #accordion-title>
                        {{ myTeamTabs[tab].displayValue }}
                    </template>
                    <template #accordion-content>
                        <component
                            :is="currentComponent.componentName"
                            v-if="currentComponent && currentComponent.key === myTeamTabs[tab].key"
                        />
                    </template>
                </recess-accordion>
            </template>
        </template>

        <template v-else>
            <recess-tab-navigation name="my-team">
                <template v-for="(tab, index) in Object.keys(myTeamTabs)">
                    <recess-tab-navigation-item
                        v-if="isTabVisible(myTeamTabs[tab].key)"
                        :key="index"
                        :tab-key="myTeamTabs[tab].key"
                        :data-test="`my-team-${myTeamTabs[tab].key}`"
                    >
                        {{ myTeamTabs[tab].displayValue }}
                    </recess-tab-navigation-item>
                </template>
            </recess-tab-navigation>
            <keep-alive>
                <component
                    :is="currentComponent.componentName"
                    v-if="currentComponent && isTabVisible(currentComponent.key)"
                />
            </keep-alive>
        </template>

        <recess-divider />
    </recess-layout-static>
</template>

<script>
import { jsMediaQueriesMixin } from '../../../mixins/mediaqueries-mixin'
import MyTeamEnrollmentsTable from '../../organisms/Tables/MyTeamEnrollmentsTable.vue'
import MyTeamPERemindersTable from '../../organisms/Tables/MyTeamPERemindersTable.vue'
import MyTeamStudyContractsTable from '../../organisms/Tables/MyTeamStudyContractsTable.vue'
import MyTeamCourseRequestTable from '../../organisms/Tables/MyTeamCourseRequestTable.vue'
import MyTeamLearningPathsTable from '../../organisms/Tables/MyTeamLearningPathsTable.vue'
import MyTeamBudgetsTable from '../../organisms/Tables/MyTeamBudgetsTable.vue'
import StudentsTable from '../../organisms/Tables/StudentsTable.vue'
import routePerimeter from '../../../perimeters/routePerimeter'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import { myTeamTabs } from '../../../constants/tabNames'

export default {
    name: 'MyTeam',
    mixins: [jsMediaQueriesMixin],
    perimeters: [routePerimeter, featuresPerimeter],
    data() {
        return {
            myTeamTabs
        }
    },
    computed: {
        currentComponent() {
            return this.getComponentToRender(this.$route?.query['my-team-tab'])
        }
    },
    created() {
        if (
            !this.$route?.query['my-team-tab'] ||
            !Object.values(this.myTeamTabs).some(
                (tab) => tab.key === this.$route?.query['my-team-tab']
            )
        ) {
            this.$router.replace({
                path: this.$route.path,
                query: { ...this.$route.query, 'my-team-tab': this.myTeamTabs.ENROLLMENTS.key }
            })
        }
    },
    methods: {
        getComponentToRender(tab) {
            switch (tab) {
                case myTeamTabs.ENROLLMENTS.key:
                    return {
                        key: myTeamTabs.ENROLLMENTS.key,
                        componentName: MyTeamEnrollmentsTable
                    }
                case myTeamTabs.STUDYCONTRACTS.key:
                    return {
                        key: myTeamTabs.STUDYCONTRACTS.key,
                        componentName: MyTeamStudyContractsTable,
                        isTabVisible: this.$isAllowed('displayStudyContract')
                    }
                case myTeamTabs.COURSEREQUESTS.key:
                    return {
                        key: myTeamTabs.COURSEREQUESTS.key,
                        componentName: MyTeamCourseRequestTable,
                        isTabVisible: this.$isAllowed('routeApprovalAbcPage')
                    }
                case myTeamTabs.PERMANENTEDUCATIONS.key:
                    return {
                        key: myTeamTabs.PERMANENTEDUCATIONS.key,
                        componentName: MyTeamPERemindersTable,
                        isTabVisible:
                            (this.$isAllowed('routeAsCoordinator') ||
                                this.$isAllowed('routeAsTeamleader')) &&
                            this.$isAllowed('displayPERemindersFeature')
                    }
                case myTeamTabs.LEARNINGPATHS.key:
                    return {
                        key: myTeamTabs.LEARNINGPATHS.key,
                        componentName: MyTeamLearningPathsTable,
                        isTabVisible:
                            this.$isAllowed('displayLearningPath') &&
                            (this.$isAllowed('routeAsCoordinator') ||
                                this.$isAllowed('routeAsTeamleader'))
                    }
                case myTeamTabs.BUDGETS.key:
                    return {
                        key: myTeamTabs.BUDGETS.key,
                        componentName: MyTeamBudgetsTable,
                        isTabVisible:
                            this.$isAllowed('displayBudgets') &&
                            (this.$isAllowed('routeAsCoordinator') ||
                                this.$isAllowed('routeAsTeamleader'))
                    }
                case myTeamTabs.USERS.key:
                    return {
                        key: myTeamTabs.USERS.key,
                        componentName: StudentsTable
                    }
                default:
                    return null
            }
        },
        isTabVisible(tab) {
            const componentToRender = this.getComponentToRender(tab)
            return componentToRender && 'isTabVisible' in componentToRender
                ? componentToRender.isTabVisible
                : true
        },
        toggledTab(isActive, tab) {
            if (isActive) {
                this.$router.replace({
                    query: { ...this.$route.query, 'my-team-tab': tab }
                })
            } else {
                this.$router.replace({
                    query: { ...this.$route.query, 'my-team-tab': undefined }
                })
            }
        }
    }
}
</script>
