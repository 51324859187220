import * as customExplanationTextTypes from './customExplanationTextTypes'

export const myTeamTabs = {
    ENROLLMENTS: { key: 'enrollments', displayValue: 'Inschrijvingen'} ,
    STUDYCONTRACTS: { key: 'study-contracts', displayValue: 'Studieovereenkomsten'} ,
    COURSEREQUESTS: { key: 'course-requests', displayValue: 'Aanvraag buiten catalogus'} ,
    PERMANENTEDUCATIONS: { key: 'permanent-educations', displayValue: 'Leerresultaten'} ,
    LEARNINGPATHS: { key: 'learning-paths', displayValue: 'Leerpaden'} ,
    BUDGETS: { key: 'budgets', displayValue: 'Budgetten'},
    USERS: { key: 'users', displayValue: 'Teamleden'} 
}

export const myDevelopmentTabs = {
    ACTIVECOURSES: {
        key: 'active-courses',
        displayValue: 'Gestarte ontwikkelactiviteiten',
        textType: customExplanationTextTypes.MY_DEVELOPMENT_ACTIVE_COURSES,
        defaultExplanationText: `Op deze pagina staan alle ontwikkelactiviteiten waarvoor geen goedkeuring meer nodig is en die u gaat starten of bent gestart.`
    },
    ENROLLMENTS: {
        key: 'enrollments',
        displayValue: 'Inschrijvingen',
        textType: customExplanationTextTypes.MY_DEVELOPMENT_ENROLLMENTS,
        defaultExplanationText: `Op deze pagina staan alle inschrijvingen die u zelf heeft gedaan en/of waar u voor ingeschreven bent door bijv.
      uw leidinggevende. Aan de "status" kunt u zien welke actie nog vereist is om daadwerkelijk te kunnen starten aan de 
      betreffende ontwikkelactiviteit.`
    },
    STUDYCONTRACTS: {
        key: 'study-contracts',
        displayValue: 'Studieovereenkomsten',
        textType: customExplanationTextTypes.MY_DEVELOPMENT_STUDYCONTRACTS,
        defaultExplanationText: `Op deze pagina staan alle studieovereenkomsten tussen u en uw werkgever. Aan de "status" kunt u zien welke actie er nog
      moet worden uitgevoerd. De studieovereenkomst is een overeenkomst tussen u en uw  werkgever over de voorwaarden die 
      gelden voor het volgen van een ontwikkelactiviteit die wordt betaald door uw werkgever.`
    },
    PERMANENTEDUCATIONS: {
        key: 'permanent-educations',
        displayValue: 'Leerresultaten',
        textType: customExplanationTextTypes.MY_DEVELOPMENT_PERMANENT_EDUCATIONS,
        defaultExplanationText: `Op deze pagina ziet u al uw Leerresultaten. U kunt via de 'Voeg een nieuw item toe' knop een nieuwe 
        herinnering toevoegen.Het kan ook zijn dat dat voor u is gedaan. In dat geval is de herinnering hier zichtbaar.`
    },
    WISHLIST: {
        key: 'wishlist',
        displayValue: 'Favorieten',
        textType: customExplanationTextTypes.MY_DEVELOPMENT_WISHLIST,
        defaultExplanationText: `Alle ontwikkelactiviteiten, die u als "favoriet" heeft aangevinkt, staan op deze pagina.
      U kunt vanaf hier de favorieten verwijderen of toevoegen aan een persoonlijk leerpad.`
    },
    LEARNINGPATHS: {
        key: 'learning-paths',
        displayValue: 'Leerpaden',
        textType: customExplanationTextTypes.MY_DEVELOPMENT_LEARNING_PATHS,
        defaultExplanationText: `Op deze pagina staan alle leerpaden die aan u zijn toegewezen (door uw leidinggevende of iemand anders in uw organisatie die
      zich bezig houdt met leerpaden). Ook staan hier uw persoonlijke leerpaden (de leerpaden die u zelf hebt aangemaakt). 
      Gebruik het filter om snel een leerpad uit uw lijst te vinden of onderscheid te maken tussen de door uzelf aangemaakte
      leerpaden en de aan u toegewezen leerpaden. Als u een ontwikkelactiviteit uit het leerpad heeft afgerond kunt u dat aangeven
      door te klikken op "Markeren als voltooid".`
    },
    RECENTLYVISITEDCOURSES: {
        key: 'recently-visited',
        displayValue: 'Recent bekeken',
        textType: customExplanationTextTypes.MY_DEVELOPMENT_RECENTLY_VISITED_COURSES,
        defaultExplanationText: `Op deze pagina ziet u de laatst door u bekeken ontwikkelactiviteiten.
                Wanneer een ontwikkelactiviteit
                niet meer beschikbaar is wordt deze uitgegrijsd.`
    }
}

export const assignLearningPathCourseTabs = {
    TEAM: {key: 'team' , displayValue: 'Team leerpaden'} ,
    PERSONAL: {key: 'personal' , displayValue: 'Persoonlijke leerpaden' } 
}

export const homeBannerFacetTabs = {
    AREAOFEXPERTISE: {key: 'areaOfExpertise' , displayValue: 'Bekijk vakgebieden '} ,
    LEVEL: {key: 'level' , displayValue: 'Bekijk opleidingsniveaus ' },
    PEERS: {key: 'peers' , displayValue: 'Ontwikkelsuggesties' } 

}

export default { myTeamTabs, myDevelopmentTabs, assignLearningPathCourseTabs, homeBannerFacetTabs }
