import oplzAccountClient from '../../api/accountClient'
import defaultBanner from '../../assets/images/banners/banner.jpg'
import * as expertiseAreas from '../../constants/expertiseArea'

// Readme: 2018-12-12
// The account will be set/reset after login/logout by watching the isAuthorized property of the userAuthentication Module.
// This is done using the initplugin of the global store -> VueApp/store/index.js
// In App.vue the authentication cookie will be used to set the isAuthorized cookie.
// After closing and opening the browser app.vue will set the isAuthorized property which in turn will fetch the user from the backend.

function defaultAccountSettings() {
    return {
        minimalOrderPriceForApproval: null,
        minimalOrderPriceForSecondApproval: null,
        displayStartMomentsType: 0,
        displayDiscountInPrices: null,
        displayDiscountSeparatelyForDetailedPrices: null,
        displayVatInPrices: null,
        displayVatSeparatelyForDetailedPrices: null,
        isPurchaseOrderMandatoryForFirstApproval: null,
        secondApprovalAccountWide: false,
        abcApprovalRequired: null,
        optionalAlternativeBillingAddress: false,
        alternativeStudyContractFlow: false,
        isBudgetEnabled: false,
        isKeyAccountEnabled: false,
        isSubsidyEnabled: false,
        isProjectsEnabled: false,
        showCapacityIconForAllCourses: false,
        shareCourseScope: 0,
        noaCompanyLicense: true,
        selectFirstApproverWithSearchInput: false,
        contactInformation: null,
        contactAlternativeText: null,
        abcRequestType: null,
        abcRequest: null,
        prefillTLCostCenter: false,
        showReportForTeamLeaders: true,
        isCostCenterEditable: true,
        isDelegationEnabled: true,
        blockEnrollmentInsufficientBudget: false,
        recentlyVisitedCourses: false,
        expertiseArea: expertiseAreas.SEGMENTS,
        cancelEnrollmentWarningText: null,
        keyAccountRequired: false,
        studentCanEditPEReminders: true,
        limitIncompanyEnrollments: false,
        hideEnrollmentPersonalData: false,
        teamLeaderSelfSearchDisabled: false,
        teamLeaderEnrollmentConfirmationText: null,
        studentEnrollmentConfirmationText: null,
        isSocialLearningEnabled: false,
        useNetPrice: false,
        learningResult: false,
        finalStartMomentDate: null,
        isAICourseSuggestionEnabled: false,
        customQuickLinksLabel: '',
        allowSkipEnrollmentVerification: false,
        projectExternalApprovalRequired: false
    }
}

const accountModule = {
    namespaced: true,
    state() {
        return {
            isFetchingAccount: false,
            isAccountFetched: false,
            account: {
                accountId: null,
                sourceId: null,
                accountCode: null,
                accountDisabledFeatures: [],
                edumsExternalId: null,
                logoUrl: null,
                email: null,
                name: null,
                phoneNumber: null,
                bannerUrl: null,
                colorPrimaryR: null,
                colorPrimaryG: null,
                colorPrimaryB: null,
                colorSecondaryR: null,
                colorSecondaryG: null,
                colorSecondaryB: null,
                colorBannerTextR: null,
                colorBannerTextG: null,
                colorBannerTextB: null,
                isEnabled: false,
                explanationDocumentUrl: null
            },
            accountSettings: defaultAccountSettings()
        }
    },
    getters: {
        studentEnrollmentConfirmationText(state) {
            return state.accountSettings.studentEnrollmentConfirmationText
        },
        teamLeaderEnrollmentConfirmationText(state) {
            return state.accountSettings.teamLeaderEnrollmentConfirmationText
        },
        blockEnrollmentInsufficientBudget(state) {
            return state.accountSettings.blockEnrollmentInsufficientBudget
        },
        bannerUrl(state) {
            return state.account.bannerUrl
        },
        isCostCenterEditable(state) {
            return state.accountSettings.isCostCenterEditable
        },
        displayStartMomentsType(state) {
            return state.accountSettings?.displayStartMomentsType
        },
        displayVatSeparatelyForDetailedPrices(state) {
            return state.accountSettings?.displayVatSeparatelyForDetailedPrices
        },
        displayVatInPrices(state) {
            return state.accountSettings?.displayVatInPrices
        },
        isPurchaseOrderMandatoryForFirstApproval(state) {
            return state.accountSettings?.isPurchaseOrderMandatoryForFirstApproval
        },
        selectFirstApproverWithSearchInput(state) {
            return state.accountSettings?.selectFirstApproverWithSearchInput
        },
        minimalOrderPriceForApproval(state) {
            return state.accountSettings?.minimalOrderPriceForApproval
        },
        limitIncompanyEnrollments(state) {
            return state.accountSettings?.limitIncompanyEnrollments
        },
        account(state) {
            return state.account
        },
        accountName(state) {
            return state.account.name
        },
        accountId(state) {
            return state.account.accountId
        },
        accountCodeName(state) {
            return state.account.accountCode
        },
        isAccountEnabled(state) {
            return state.account.isEnabled
        },
        accountContactInfo(state) {
            return {
                email: state.account?.email,
                phoneNumber: state.account?.phoneNumber
            }
        },
        shareCourseScope(state) {
            return state.accountSettings?.shareCourseScope
        },
        noaCompanyLicense(state) {
            return state.accountSettings?.noaCompanyLicense
        },
        alternativeStudyContractFlow(state) {
            return state.accountSettings?.alternativeStudyContractFlow
        },
        isSubsidyEnabled(state) {
            return state.accountSettings?.isSubsidyEnabled
        },
        explanationDocumentUrl(state) {
            return state.account.explanationDocumentUrl
        },
        contactAlternativeText(state) {
            return state.accountSettings?.contactAlternativeText
        },
        prefillTLCostCenter(state) {
            return state.accountSettings?.prefillTLCostCenter
        },
        hasAlternativeBillingAddress(state) {
            return state.accountSettings?.optionalAlternativeBillingAddress
        },
        expertiseArea(state) {
            return state.accountSettings?.expertiseArea
        },
        cancelEnrollmentWarningText(state) {
            return (
                state.accountSettings.cancelEnrollmentWarningText ||
                'U kunt alleen kosteloos annuleren wanneer de aanvangsdatum van de ontwikkelactiviteit méér dan 2 weken in de toekomst ligt t.o.v. de annuleringsdatum. Is deze periode korter, dan wordt het bedrag van de ontwikkelactiviteit alsnog in rekening gebracht.'
            )
        },
        isBudgetEnabled(state) {
            return state.accountSettings?.isBudgetEnabled
        },
        keyAccountRequired(state) {
            return state.accountSettings?.keyAccountRequired
        },
        studentCanEditPEReminders(state) {
            return state.accountSettings?.studentCanEditPEReminders
        },
        teamLeaderSelfSearchDisabled(state) {
            return state.accountSettings?.teamLeaderSelfSearchDisabled
        },
        useNetPrice(state) {
            return state.accountSettings?.useNetPrice
        },
        isLearningResultEnabled(state) {
            return state.accountSettings?.learningResult
        },
        showCapacityIconForAllCourses(state) {
            return state.accountSettings?.showCapacityIconForAllCourses
        },
        finalStartMomentDate(state) {
            return state.accountSettings?.finalStartMomentDate
        },
        isAICourseSuggestionEnabled(state) {
            return state.accountSettings?.isAICourseSuggestionEnabled
        },
        customQuickLinksLabel(state) {
            return state.accountSettings?.customQuickLinksLabel
        },
        allowSkipEnrollmentVerification(state) {
            return state.accountSettings?.allowSkipEnrollmentVerification
        },
        isProjectExternalApprovalRequired(state) {
            return state.accountSettings?.projectExternalApprovalRequired
        }
    },
    mutations: {
        resetState(state) {
            state.isFetchingAccount = false
            state.isAccountFetched = false
            state.account = {
                accountId: null,
                sourceId: null,
                accountCode: null,
                accountDisabledFeatures: [],
                edumsExternalId: null,
                logoUrl: null,
                colorPrimaryR: null,
                colorPrimaryG: null,
                colorPrimaryB: null,
                colorSecondaryR: null,
                colorSecondaryG: null,
                colorSecondaryB: null,
                isEnabled: false,
                explanationDocumentUrl: null
            }
            state.accountSettings = defaultAccountSettings()
        },
        setIsFetchingAccount(state, isFetchingAccount) {
            state.isFetchingAccount = isFetchingAccount
        },
        setIsAccountFetched(state, isAccountFetched) {
            state.isAccountFetched = isAccountFetched
        },
        setAccount(state, account) {
            Object.keys(state.account).forEach(key => {
                state.account[key] = account[key]
            })

            if (account?.settings) {
                Object.keys(state.accountSettings).forEach(key => {
                    state.accountSettings[key] = account.settings[key]
                })
            }

            // Set the default banner if banner is not provided
            state.account.bannerUrl = account.bannerUrl || defaultBanner
        }
    },
    actions: {
        async fetchAccount({ commit }) {
            try {
                commit('setIsFetchingAccount', true)
                const currentAccount = await oplzAccountClient.getCurrentAccount()
                commit('setAccount', currentAccount)
                return currentAccount
            } catch (error) {
                if (error?.response?.status !== 401) throw new Error(error)
                return null
            } finally {
                commit('setIsFetchingAccount', false)
                commit('setIsAccountFetched', true)
            }
        },
        resetState({ commit }) {
            commit('resetState')
        }
    }
}

export default accountModule
