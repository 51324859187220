<template>
  <div class="c-search-result s-search-result">
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>
    <!-- Course search bar -->
    <search-suggestions-component
      :show-autocomplete="false"
      class="c-searchsuggestion--tall"
    />
    <recess-divider />

    <!-- Search keyword -->
    <course-search-title />
    <recess-divider />

    <!-- Page controls -->
    <recess-card
      :variant="cardVariant"
      bordered
      has-transition
    >
      <course-search-toolbar
        @toggleFacets="toggleFacets"
        @clear-filters="clearFilters"
      />

      <!-- Facet filters -->

      <transition name="slide">
        <div v-show="showFacets">
          <course-search-facets class="c-facet--wrapper">
            <h3 slot="facets-title">Filter gevonden ontwikkelactiviteiten</h3>
          </course-search-facets>
        </div>
      </transition>
    </recess-card>

    <recess-divider />

    <!-- Search result -->

    <course-search-items>
      <template
        slot="search-items-content"
        slot-scope="props"
      >
        <course-search-item
          :item="props.item"
          :is-card-clickable="false"
          @click.native="goToCoursePage(props.item)"
          @click.prevent
        >
          <template slot="search-item-action-buttons">
            <recess-button
              title="Bekijk details"
              variant="tertiary"
              class="c-search-result__button"
              @click="goToCoursePage(props.item)"
            />
            <recess-button
              v-if="showEnrollmentButton(props.item)"
              title="Inschrijven"
              variant="secondary"
              class="c-search-result__button"
              @click="setSelectedCourse(props.item)"
            />
          </template>
        </course-search-item>
      </template>
    </course-search-items>

    <recess-divider />

    <course-search-paginator />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { scrollTo } from 'vue-scrollto'
import * as courseLearningMethodTypes from '../../../../../constants/courseLearningMethodTypes'
import CourseSearchItem from '../../../CourseSearchComponent/CourseSearchItems/CourseSearchItem.vue'
import CourseSearchItems from '../../../CourseSearchComponent/CourseSearchItems/CourseSearchItems.vue'
import CourseSearchPaginator from '../../../CourseSearchComponent/CourseSearchPaginator.vue'
import SearchSuggestionsComponent from '../../../SearchSuggestionsComponent/SearchSuggestionsComponent.vue'
import CourseSearchToolbar from '../../../CourseSearchComponent/CourseSearchToolbar.vue'
import CourseSearchFacets from '../../../CourseSearchComponent/CourseSearchFacets/CourseSearchFacets.vue'
import CourseSearchTitle from '../../../CourseSearchComponent/CourseSearchTitle.vue'
import LoaderComponent from '../../../../atoms/LoaderComponent/LoaderComponent.vue'
import featuresPerimeter from '../../../../../perimeters/featuresPerimeter'

export default {
    name: 'CourseSearchComponent',
    components: {
        CourseSearchItem,
        CourseSearchPaginator,
        SearchSuggestionsComponent,
        CourseSearchToolbar,
        CourseSearchFacets,
        CourseSearchTitle,
        CourseSearchItems,
        LoaderComponent
    },
    perimeters: [featuresPerimeter],
    data() {
        return {
            showFacets: false
        }
    },

    computed: {
        ...mapGetters('enrollmentModule', ['selectedStudentsId']),
        ...mapGetters("searchModule", ["isLoading"]),
        cardVariant() {
            return this.showFacets ? '' : 'variant1'
        }
    },
    watch: {
        $route(to, from) {
            if (to.query.q !== from.query.q) {
                this.resetState()
                this.setQuery(to.query.q)
                this.setUserFilters(this.selectedStudentsId)
                this.fetchResults()
                scrollTo('body')
            }
        }
    },
    mounted() {
        this.setSearchFromTeamAssortments(false)
        this.setUserFilters(this.selectedStudentsId)
        this.fetchResults()
        scrollTo('body')
    },
    methods: {
        ...mapActions('searchModule', ['fetchResults', 'resetLocationRadiusFilters']),
        ...mapMutations('searchModule', ['resetState', 'setQuery', 'setUserFilters', 'setSearchFromTeamAssortments']),
        ...mapMutations('enrollmentModule', ['setCourseAssortment', 'setCourse']),
        toggleFacets() {
            this.showFacets = !this.showFacets
        },
        showEnrollmentButton(item) {
            return item?.startMoments?.length || item?.learningMethodType?.toLowerCase() === courseLearningMethodTypes.SELFPLANNED
        },
        setSelectedCourse(item) {
            this.setCourseAssortment(item.assortmentId)
            
            this.$emit('course-selected', item.id, item.assortmentId)
        },
        async goToCoursePage(item) {
            // Reset previous selected course
            await this.setCourse(null)
            await this.setCourseAssortment(item.assortmentId)
            await this.$router.push({ query: {
                ...this.$route.query,
                course: item.id,
                assortment: item.assortmentId
            }})
        },
        clearFilters() {
            const q = this.$route?.query?.q
            this.resetState()
            this.resetLocationRadiusFilters()
            this.setQuery(q)
            this.setUserFilters(this.selectedStudentsId)
            this.fetchResults()
        }
    }
}
</script>