<template>
  <div
    class="c-search-result s-search-result"
    data-test="search-result"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
      />
    </transition>

    <course-search-data />

    <!-- Search keyword -->
    <div class="d-flex justify-content-between flex-column flex-md-row">
      <h3>Vind een ontwikkelactiviteit</h3>
      <course-search-title />
    </div>

    <!-- Teamleader assortment selection -->
    <search-type-component @search-type-changed="val => setSearchType(val)" />
  
    <!-- Course search bar -->
    <search-suggestions-component class="c-searchsuggestion--tall mt-4" />

    <recess-divider variant="xsmall" />

    <span
      v-if="showTeamLeaderInfoText"
      data-test="team-leader-info-text"
    >
      Let op: u kunt hier op de zoekpagina alleen zien wat er beschikbaar is voor uw medewerkers. 
      Inschrijven doet u via de “Teamleden inschrijven” knop op het dashboard óf via “Mijn team” → “Teamleden”.
    </span>

    <recess-divider variant="xsmall" />

    <!-- Page controls -->
    <recess-card
      :variant="cardVariant"
      :bordered="true"
      :has-transition="true"
      class="mb-3"
    >
      <course-search-toolbar
        @toggleFacets="toggleFacets"
        @clear-filters="clearFilters"
      />

      <!-- Facet filters -->
      <transition name="slide">
        <div v-show="showFacets">
          <course-search-facets class="c-facet--wrapper">
            <h3 slot="facets-title">Filter gevonden ontwikkelactiviteiten</h3>
            <router-link
              v-if="$isAllowed('displayABCRequestStandard')"
              slot="facets-footer"
              :to="{ name: 'abc-request'}"
            >
              Aanvraag buiten catalogus
            </router-link>
          </course-search-facets>
        </div>
      </transition>
    </recess-card>

    <template v-if="showGoodhabitzText">
      <good-habitz-text />
    </template>
        
    <recess-divider />

    <!-- Search results -->
    <div class="u-position-relative">
      <course-search-items />

      <recess-divider />

      <course-search-paginator />

      <recess-divider />

      <recess-card
        v-if="$isAllowed('displayABCRequestStandard')"
        variant="variant1"
      >
        <h4 class="mb-md-0 mb-sm-2">Kunt u uw ontwikkelactiviteit niet vinden?</h4>
        <div class="d-md-flex align-items-md-center justify-content-md-between">
          <p class="mb-md-0 mb-sm-3">Geef aan wat u zoekt en misschien kunnen we u toch helpen.</p>
          <recess-button
            title="Start aanvraag"
            variant="secondary"
            :url="{ name: 'abc-request'}"
          />
        </div>
      </recess-card>
    </div>

    <recess-divider
      :display-medium-viewport="false"
      :display-large-viewport="false"
      margin-variant-small-viewport="variant1"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import routePerimeter from '../../../perimeters/routePerimeter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import SearchSuggestionsComponent from '../SearchSuggestionsComponent/SearchSuggestionsComponent.vue'
import SearchTypeComponent from '../SearchSuggestionsComponent/SearchTypeComponent.vue'
import CourseSearchData from './CourseSearchData.vue'
import CourseSearchFacets from './CourseSearchFacets/CourseSearchFacets.vue'
import CourseSearchItems from './CourseSearchItems/CourseSearchItems.vue'
import CourseSearchPaginator from './CourseSearchPaginator.vue'
import CourseSearchTitle from './CourseSearchTitle.vue'
import CourseSearchToolbar from './CourseSearchToolbar.vue'
import GoodHabitzText from './GoodHabitzText.vue'

export default {
    name: 'CourseSearchComponent',
    perimeters: [featuresPerimeter, routePerimeter],
    components: {
        CourseSearchData,
        CourseSearchItems,
        CourseSearchPaginator,
        SearchSuggestionsComponent,
        CourseSearchToolbar,
        CourseSearchFacets,
        CourseSearchTitle,
        LoaderComponent,
        SearchTypeComponent,
        GoodHabitzText
    },
    data() {
        return {
            showFacets: false
        }
    },
    computed: {
        ...mapState("searchModule", ["searchFromTeamAssortments"]),
        ...mapGetters("searchModule", ["isLoading"]),
        ...mapGetters("accountModule", ["accountCodeName"]),
        ...mapGetters("accountModule", ["teamLeaderSelfSearchDisabled"]),
        cardVariant() {
            return this.showFacets ? '' : 'variant1'
        },
        showTeamLeaderInfoText() {
            return this.searchFromTeamAssortments && this.$isAllowed('routeAsTeamleader')
        },
        showGoodhabitzText() {
            const subscribedAccountCodeNames = ["RNL", 'TTG', "YGN"]

            return subscribedAccountCodeNames.find(accountCodeName => accountCodeName === this.accountCodeName.toUpperCase())
        }
    },
    destroyed() {
        this.resetSearchModuleState()
    },
    methods: {
        ...mapMutations("searchModule", {
            resetSearchModuleState: "resetState",
            setSearchFromTeamAssortments: "setSearchFromTeamAssortments",
            setQuery: "setQuery"
        }),
        ...mapActions("searchModule", ["fetchResults", 'resetLocationRadiusFilters']),
        ...mapMutations("compareCoursesModule", {
            resetCompareCoursesModuleState: "resetState"
        }),
        toggleFacets() {
            this.showFacets = !this.showFacets
        },
        setSearchType(val) {
            this.showFacets = false
            // Clear facet filters and search type in url, if any
            if (this.$route.query.facetFilters || this.$route.query.searchType) {
                this.$router.replace({query: { q: '' }})
            }
            this.resetAndFetchResults(val)
            // Reset selected course to compare
            this.resetCompareCoursesModuleState()            
        },
        clearFilters() {
            const query = this.$route.query.q || ''
            if (this.$route.query.facetFilters) {
                this.$router.push({
                    path: this.$route.path,
                    query: { q: query }
                })
            } else {
                const searchFromTeamAssortments = this.searchFromTeamAssortments
                this.resetAndFetchResults(searchFromTeamAssortments)
            }
        },
        resetAndFetchResults(searchFromTeamAssortments) {
            this.resetSearchModuleState()            
            this.setQuery(this.$route.query?.q)
            this.setSearchFromTeamAssortments(searchFromTeamAssortments)
            this.resetLocationRadiusFilters(searchFromTeamAssortments)
            this.fetchResults()
        }
    }
}
</script>