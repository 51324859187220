<template>
  <section>
    <h4>Uitvoerder van het project</h4>

    <div class="row pb-3">
      <div class="col-md-6 w-100">
        <recess-input
          :value="name"
          :error-message="handleErrorMessage('name')"
          data-test="project-name-input"
          class="w-100 mr-md-3 mb-3 mb-md-0"
          label-text="Naam projectaanvraag *"
          @input="(val) => $emit('input:name', val)"
        />
      </div>
      <div class="col-md-6 w-100">
        <recess-select
          :value="type"
          :options="projectRequestTypeOptions"
          :prefill-with-first-option="false"
          :error-message="handleErrorMessage('type')"
          data-test="project-type-select"
          class="w-100"
          label-text="Type projectaanvraag *"
          @input="(val) => $emit('input:type', val)"
        />
      </div>
    </div>
    <div class="row pb-3">
      <div v-if="isIncompanyType" class="col-md-6 w-100">
        <recess-date-picker
          :value="preferredExecutionDate"
          :error-message="handleErrorMessage('preferredExecutionDate')"
          placeholder="dd-mm-yyyy"
          class="w-100 mr-md-3 mb-3 mb-md-0"
          label-text="Gewenste uitvoerdatum *"
          data-test="project-preferred-execution-date"
          @input="(val) => $emit('input:preferred-execution-date', val)"
        />
      </div>
      <div
        v-if="type === projectRequestTypes.IncompanyWithStartmoments"
        class="col-md-6 w-100"
      >
        <recess-input
          type="number"
          :value="numberOfStartmoments"
          min="1"
          step="1"
          data-test="project-number-of-startmoments-input"
          class="w-100 mr-md-3 mb-3 mb-md-0"
          label-text="Aantal startmomenten *"
          :error-message="handleErrorMessage('numberOfStartmoments')"
          @input="(val) => $emit('input:number-of-startmoments', val)"
        />
      </div>
    </div>

    <recess-textarea-input
      :value="description"
      :rows="2"
      label-text="Beschrijving projectaanvraag"
      data-test="project-description-textarea"
      @input="(val) => $emit('input:description', val)"
    />
  </section>
</template>

<script>
import projectRequestTypes, {
    projectRequestTypeValues
} from '../../../../../constants/projectRequestTypes'

export default {
    props: {
        name: {
            type: String,
            default: null
        },
        type: {
            type: Number,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        preferredExecutionDate: {
            type: String,
            default: null
        },
        numberOfStartmoments: {
            type: Number,
            default: null
        },
        isIncompanyType: {
            type: Boolean,
            default: false
        },
        handleErrorMessage: {
            type: Function,
            default: () => null
        }
    },
    emits: [
        'input:name',
        'input:description',
        'input:type',
        'input:preferred-execution-date',
        'input:number-of-startmoments'
    ],
    setup() {
        const projectRequestTypeOptions = Object.values(projectRequestTypeValues)

        return {
            projectRequestTypes,
            projectRequestTypeOptions
        }
    }
}
</script>
