<template>
  <section>
    <h4 data-test="section-title">Goedkeuring</h4>

    <div class="row pb-3">
      <div class="col-md-6 w-100">
        <recess-autocomplete
          :value="assignedApproverEmail"
          placeholder="Naam of e-mail"
          data-test="assigned-approver-email-input"
          label-text="Beoordelaar *"
          item-text="email"
          item-sub-text="name"
          :error-message="handleErrorMessage('assignedApproverEmail')"
          :on-query="handleProjectApproverQueryChange"
          @input="(val) => $emit('input:assigned-approver-email', val)"
        />
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-md-6 w-100">
        <recess-select
          :value="costCenterType"
          :options="projectCostCenterTypeOptions"
          :disabled="isProjectExternalApprovalRequired"
          :error-message="handleErrorMessage('costCenterType')"
          data-test="cost-center-type-select"
          class="w-100 mr-md-3 mb-3 mb-md-0"
          label-text="Te factureren kostenplaats *"
          @input="(val) => $emit('input:cost-center-type', val)"
        />
      </div>
      <div class="col-md-6 w-100">
        <recess-input
          :value="costCenter"
          :label-text="costCenterLabel"
          :disabled="!isCostCenterRequired"
          :error-message="handleErrorMessage('costCenter')"
          data-test="cost-center-input"
          class="w-100 mr-md-3 mb-3 mb-md-0"
          @input="(val) => $emit('input:cost-center', val)"
        />
      </div>
    </div>

    <recess-alert
      v-if="showApproverEmptyCostCenterWarning"
      type="info"
      class="mb-4"
      text="De geselecteerde goedkeurder is niet gekoppeld aan een kostenplaats. Met deze keuze zal er géén kostenplaats toegekend worden aan de order. Het is niet mogelijk om na indienen een kostenplaats toe te voegen of te wijzigen."
      data-test="approver-cost-center-empty-alert"
    />

    <recess-textarea-input
      :rows="2"
      :value="approverComment"
      class="w-100"
      label-text="Aanvullende informatie/opmerking"
      data-test="approver-comment-textarea"
      @input="(val) => $emit('input:approver-comment', val)"
    />
  </section>
</template>

<script>
import { computed } from 'vue'
import projectCostCenterType, {
    projectCostCenterTypeValues
} from '../../../../../constants/projectRequestCostCenterType'
import useStore from '../../../../../composables/useStore'

export default {
    props: {
        assignedApproverEmail: {
            type: String,
            default: null
        },
        approverComment: {
            type: String,
            default: null
        },
        costCenterType: {
            type: Number,
            default: null
        },
        costCenter: {
            type: String,
            default: null
        },
        handleErrorMessage: {
            type: Function,
            default: () => null
        },
        handleProjectApproverQueryChange: {
            type: Function,
            default: () => null
        }
    },
    emits: [
        'input:assigned-approver-email',
        'input:approver-comment',
        'input:cost-center-type',
        'input:cost-center'
    ],
    setup(props) {
        const store = useStore()

        const isProjectExternalApprovalRequired = computed(() => {
            return store.getters['accountModule/isProjectExternalApprovalRequired']
        })

        const isCostCenterRequired = computed(
            () => props.costCenterType === projectCostCenterType.Central
        )

        const costCenterLabel = computed(
            () => `Kostenplaats${isCostCenterRequired.value ? ' *' : ''}`
        )

        const projectCostCenterTypeOptions = computed(() =>
            isProjectExternalApprovalRequired.value
                ? [projectCostCenterTypeValues[projectCostCenterType.Approver]]
                : Object.values(projectCostCenterTypeValues)
        )

        const showApproverEmptyCostCenterWarning = computed(
            () =>
                isProjectExternalApprovalRequired.value &&
                !!props.assignedApproverEmail &&
                !props.costCenter
        )

        return {
            isProjectExternalApprovalRequired,
            projectCostCenterTypeOptions,
            isCostCenterRequired,
            costCenterLabel,
            showApproverEmptyCostCenterWarning
        }
    }
}
</script>
