import { getOplzClient } from './apiClient'

const baseUrl = '/elearningsubscription'

const postELearningSubscription = async data => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}`, data).then(response => {
        return response?.data
    })
}

const softDeleteELearningSubscription = async id => {
    const client = await getOplzClient()
    return client.delete(`${baseUrl}/softDelete/${id}`).then(response => {
        return response?.data
    })
}

const toggleCompletion = async id => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/toggle-completion`).then(response => {
        return response?.data
    })
}

export default {
    postELearningSubscription,
    softDeleteELearningSubscription,
    toggleCompletion
}
