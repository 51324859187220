import dataQueryFilterOperator from "../../api/DataQuery/dataQueryFilterOperator"
import tableHelper from "../../modules/tableHelper"

export default class TableFilterBuilder {
    _filters = {}

    fullName(operator = dataQueryFilterOperator.CONTAINS) {
        this._filters.fullNameFilter = {
            key: 'FullName',
            value: '',
            operator
        }

        return this
    }

    employeeNumber(operator = dataQueryFilterOperator.CONTAINS) {
        this._filters.employeeNumberFilter = {
            key: 'EmployeeNumber',
            value: '',
            operator
        }

        return this
    }

    email(operator = dataQueryFilterOperator.CONTAINS) {
        this._filters.emailFilter = {
            key: 'EmailAddress',
            value: '',
            operator
        }

        return this
    }

    department(operator = dataQueryFilterOperator.CONTAINS) {
        this._filters.department = {
            key: 'UserDepartment',
            value: '',
            operator
        }

        return this
    }

    courseVariant(operator = dataQueryFilterOperator.EQUALS) {
        this._filters.variantsFilter = {
            key: 'CourseVariant',
            value: '',
            operator
        }
                
        return this
    }

    courseName(operator = dataQueryFilterOperator.CONTAINS) {
        this._filters.courseNameFilter = {
            key: 'CourseName',
            value: '',
            operator
        }

        return this
    }

    enrollmentStatus(operator = dataQueryFilterOperator.EQUALS) {
        this._filters.statusFilter = {
            key: 'StatusId',
            value: '',
            operator
        }

        return this
    }

    createdBy(operator = dataQueryFilterOperator.CONTAINS) {
        this._filters.createdByFilter ={
            key: 'CreatedBy',
            value: '',
            operator
        }

        return this
    }

    isSameCompany(operator = dataQueryFilterOperator.EQUALS) {
        this._filters.sameCompanyFilter = {
            key: 'SameCompanyApprover',
            value: '',
            operator
        }

        return this
    }

    delegator(operator = dataQueryFilterOperator.EQUALS) {
        this._filters.delegatorFilter = {
            key: 'DelegatorId',
            value: '',
            operator
        }

        return this
    }

    creationDateRange(operator = dataQueryFilterOperator.DYNAMIC) {
        this._filters.creationDateFilter = {
            fromValue: '',
            toValue: '',
            operator,
            key: 'DateTimeCreated.Date'
        }

        return this
    }

    priceRange(useNetPrice = false, operator = dataQueryFilterOperator.DYNAMIC) {
        this._filters.priceFilter = {
            fromValue: '',
            toValue: '',
            operator,
            key: tableHelper.totalAmountWithOrWithoutVatKey(useNetPrice)
        }

        return this
    }

    build() {
        return this._filters
    }
}
